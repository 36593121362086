






































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {dispatchGenerateCustomAccessCode, dispatchGetAccessCodeLogs} from '@/store/crud/actions';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {IAccessCode, IAccessCodeLog} from '@/interfaces/accessCode';
import moment from 'moment';

@Component
export default class CreateCode extends AppComponent {
  @Prop({type: Object}) public selectedAccessCode: IAccessCode | null;
  @Prop({type: Boolean}) public isFetchingCode: boolean;
  public accessCodeTypes: string[] = ['Custom', 'Partner'];
  public codeType: 'Custom' | 'Partner' | '' = '';
  public code: string = '';
  public lname: string = '';
  public fname: string = '';
  public email: string = '';
  public notes: string = '';

  public accessCodeLogs: IAccessCodeLog[] = [];
  public headers = [
    {
      text: 'Referred email',
      sortable: true,
      value: 'referred_email',
      align: 'left',
    },
    {
      text: 'Date',
      sortable: true,
      value: 'added_at',
      align: 'left',
    },
    {
      text: 'Access code',
      sortable: true,
      value: 'access_code_used',
      align: 'left',
    },
  ];

  public valid: boolean = false;

  public codeTypeRules = [(v: string) => !!v || 'Code type is required'];

  public accessCodeRules = [(v: string) => !!v || 'Access code is required'];

  public emailRequiredRules = [(v: string) => !!v || 'Email is required'];

  public emailValidRules = [
    (v: string) => (!!v ? /.+@.+\..+/.test(v) || 'E-mail must be valid' : true),
  ];

  public fnameRules = [(v: string) => !!v || 'First name is required'];

  public lnameRules = [(v: string) => !!v || 'Last name is required'];

  private patient_id: string = '';

  public get isEditingMode() {
    return !!this.$router.currentRoute.params.id && !!this.selectedAccessCode;
  }

  public formatDate(date: string) {
    return moment(date).format('MM-DD-YYYY hh:mm');
  }

  public async submit() {
    if (await (this.$refs.form as any).validate()) {
      const accessCode: Partial<IAccessCode> = {
        access_code: this.codeType === 'Custom' ? this.code : undefined,
        email: this.email,
        fname: this.fname,
        lname: this.lname,
        notes: this.notes,
      };
      const response = await dispatchGenerateCustomAccessCode(this.$store, {accessCode});
      if (response) {
        this.$emit('updateCodesList');
        await this.$router.push({
          name: 'main-admin-accesscode-list',
        });
      }
      // TODO: this is for the member type
      /*const patient: IPatient | null = await dispatchGetPatientByEmail(this.$store, this.email);
      if (patient?.id) {
        await dispatchGeneratePartnerAccessCode(this.$store, {
          accessCode: {
            ...accessCode,
            patient_id: patient.id
          }
        });
        this.$emit("updateCodesList");
      } else {
        commitAddNotification(this.$store, { content: 'Patient with this email was not found', color: 'error' });
      }*/
    }
  }

  public async reset() {
    await this.$validator.reset();
    this.codeType = '';
    this.code = '';
    this.email = '';
    this.notes = '';
    this.patient_id = '';
  }

  @Watch('isEditingMode')
  public async fetchLogs() {
    if (this.isEditingMode && this.selectedAccessCode?.id) {
      const logs = await dispatchGetAccessCodeLogs(this.$store, {
        accessCode: this.selectedAccessCode,
      });
      if (logs) {
        this.accessCodeLogs = logs;
      }
    }
  }

  public mounted() {
    this.fetchLogs();
  }
}
